<template>
  <c-box
    w="100%"
    max-width="900px"
    mx="auto"
    p="1rem"
  >
    <c-flex
      :d="['flex', 'none']"
      flex-direction="row"
      mb="1rem"
      w="100%"
      align-items="center"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_atas.webp?updatedAt=1682497423574"
        w="50px"
        mr="1rem"
        flex-shrink="0"
      />
      <c-box flex-grow="1">
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          mb="1rem"
        >
          Terima kasih telah memilih Dietela untuk membantu kamu merubah kebiasaanmu!
        </c-text>
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
        >
          Setelah isi formulir gizi ada beberapa hal yang perlu kamu ketahui:
        </c-text>
      </c-box>
    </c-flex>
    <c-flex
      mb="1rem"
      flex-direction="row"
    >
      <c-image
        :d="['none', 'block']"
        src="https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_atas.webp?updatedAt=1682497423574"
        w="150px"
        mr="1rem"
        flex-shrink="0"
      />
      <c-box flex-grow="1">
        <c-text
          :d="['none', 'block']"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          mb="1rem"
        >
          Terima kasih telah memilih Dietela untuk membantu kamu merubah kebiasaanmu!
        </c-text>
        <c-text
          :d="['none', 'block']"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          mb="1rem"
        >
          Setelah isi formulir gizi ada beberapa hal yang perlu kamu ketahui:
        </c-text>
        <c-box>
          <c-list 
            as="ol" 
            style-type="decimal"
            style-pos="outside"
            font-family="Roboto"
            :font-size="['14px', '16px']"
            mb="1rem"
            pl="20px"
          >
            <c-list-item>
              Kamu akan terhubung dengan Ahli Gizi melalui <c-box
                as="span"
                font-weight="600"
              >
                grup pendampingan di WhatsApp
              </c-box>, yang beranggotakan 1 klien, 1 ahli gizi dan klien manajer.
            </c-list-item>
            <c-list-item>
              Pada <c-box
                as="span"
                font-weight="600"
              >
                grup pendampingan di WhatsApp
              </c-box>, Ahli Gizi akan membuat janji video call denganmu untuk konseling pertama. Sebaiknya segera lakukan video call, melalui video call Ahli Gizi akan menerangkan apa yang perlu kamu ketahui untuk memperbaiki kondisi diet dan kesehatanmu
            </c-list-item>
            <c-list-item>
              Selanjutnya ahli gizi akan membuatkan personalized meal plan* untukmu. Sambil menunggu bergabung di grup pendampingan Whatsapp & personalized meal plan selesai dibuatkan, <c-box
                as="span"
                font-weight="600"
              >
                kamu bisa membuka menu “Panduan Diet”
              </c-box> untuk membaca panduan yang dapat mendukung keberhasilanmu mencapai target.
            </c-list-item>
          </c-list>
        </c-box>
        <c-box
          w="100%"
          :text-align="['center', 'left']"
        >
          <c-button
            background-color="#F1E456"
            color="#008C81"
            font-family="Roboto"
            font-size="14px"
            border-radius="16px"
            as="router-link"
            :to="{ name: 'client.content-challenge' }"
          >
            Panduan Diet
          </c-button>
        </c-box>
      </c-box>
    </c-flex>
    <c-box
      background-color="#FDEDCB"
      font-family="Roboto"
      :font-size="['14px', '16px']"
      :py="['0.75rem', '1rem']"
      :px="['0.75rem', '3rem']"
      mb="1rem"
      align-items="center"
    >
      Grup pendampingan di WhatsApp akan dimulai dalam 1 x 24 jam setelah mengisi Formulir Gizi. Jika pengisian Formulir Gizi dilakukan pada hari Sabtu, Minggu, dan hari libur nasional, maka grup pendampingan di WhatsApp akan dimulai pada hari Senin atau hari kerja pertama.
    </c-box>
    <c-text
      font-family="Roboto"
      :font-size="['14px', '18px']"
      :font-weight="[400, 500]"
      mb="1rem" 
    >
      Jika ada kendala selama program, kamu bebas untuk menyampaikannya kepada klien manajer ya.
    </c-text>
    <c-flex
      w="100%"
      :flex-direction="['row-reverse', 'row']"
      :py="['0.75rem', '1rem']"
      :px="['0.75rem', '3rem']"
      mb="1rem"
      align-items="center"
      background="#C7F9E3"
    >
      <c-text
        font-family="Roboto"
        flex-grow="1"
        :ml="['12px', '0px']"
        :mr="['0px', '12px']"
        :font-size="['14px', '16px']"
      >
        Kami disini untuk membantu kamu merubah kebiasaan menjadi sehat dan mencapai goal sehatmu, tapi untuk mencapainya kami meminta kerjasamanya untuk selalu terbuka akan kesulitan kamu, sehingga kami dapat maksimal memberikan rekomendasi, untukmu mencapai hidup lebih sehat :)
      </c-text>
      <c-image 
        flex-shrink="0"
        src="https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_eating.webp?updatedAt=1682497423559"
        :w="['60px', '72px']"
      />
    </c-flex>
    <c-text
      font-family="Roboto"
      :font-size="['10px', '16px']"
      font-style="italic"
      mb="1rem"
      line-height="150%"
    >
      *Standar operasional dietela: Personalized meal plan dapat diakses dalam 2 x 24 jam setelah video konseling awal. Bagi klien yang berlangganan lebih dari 30 hari, personalized meal plan dapat diakses 2 x 24 jam setelah konseling evaluasi setiap bulannya.
    </c-text>
    <c-flex 
      flex-direction="column" 
      font-family="Roboto"
      :font-size="['12px', '16px']"
      line-height="150%"
    >
      <c-text>
        Salam Sehat,
      </c-text>
      <c-text>
        Nutrition Service Director
      </c-text>
      <c-text>
        Ulfa Safira, S.Gz.
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'NotificationPage',
}
</script>
