var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "900px",
      "mx": "auto",
      "p": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "d": ['flex', 'none'],
      "flex-direction": "row",
      "mb": "1rem",
      "w": "100%",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_atas.webp?updatedAt=1682497423574",
      "w": "50px",
      "mr": "1rem",
      "flex-shrink": "0"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "mb": "1rem"
    }
  }, [_vm._v(" Terima kasih telah memilih Dietela untuk membantu kamu merubah kebiasaanmu! ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Setelah isi formulir gizi ada beberapa hal yang perlu kamu ketahui: ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "mb": "1rem",
      "flex-direction": "row"
    }
  }, [_c('c-image', {
    attrs: {
      "d": ['none', 'block'],
      "src": "https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_atas.webp?updatedAt=1682497423574",
      "w": "150px",
      "mr": "1rem",
      "flex-shrink": "0"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "mb": "1rem"
    }
  }, [_vm._v(" Terima kasih telah memilih Dietela untuk membantu kamu merubah kebiasaanmu! ")]), _c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "mb": "1rem"
    }
  }, [_vm._v(" Setelah isi formulir gizi ada beberapa hal yang perlu kamu ketahui: ")]), _c('c-box', [_c('c-list', {
    attrs: {
      "as": "ol",
      "style-type": "decimal",
      "style-pos": "outside",
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "mb": "1rem",
      "pl": "20px"
    }
  }, [_c('c-list-item', [_vm._v(" Kamu akan terhubung dengan Ahli Gizi melalui "), _c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": "600"
    }
  }, [_vm._v(" grup pendampingan di WhatsApp ")]), _vm._v(", yang beranggotakan 1 klien, 1 ahli gizi dan klien manajer. ")], 1), _c('c-list-item', [_vm._v(" Pada "), _c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": "600"
    }
  }, [_vm._v(" grup pendampingan di WhatsApp ")]), _vm._v(", Ahli Gizi akan membuat janji video call denganmu untuk konseling pertama. Sebaiknya segera lakukan video call, melalui video call Ahli Gizi akan menerangkan apa yang perlu kamu ketahui untuk memperbaiki kondisi diet dan kesehatanmu ")], 1), _c('c-list-item', [_vm._v(" Selanjutnya ahli gizi akan membuatkan personalized meal plan* untukmu. Sambil menunggu bergabung di grup pendampingan Whatsapp & personalized meal plan selesai dibuatkan, "), _c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": "600"
    }
  }, [_vm._v(" kamu bisa membuka menu “Panduan Diet” ")]), _vm._v(" untuk membaca panduan yang dapat mendukung keberhasilanmu mencapai target. ")], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "text-align": ['center', 'left']
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": "#F1E456",
      "color": "#008C81",
      "font-family": "Roboto",
      "font-size": "14px",
      "border-radius": "16px",
      "as": "router-link",
      "to": {
        name: 'client.content-challenge'
      }
    }
  }, [_vm._v(" Panduan Diet ")])], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "background-color": "#FDEDCB",
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "py": ['0.75rem', '1rem'],
      "px": ['0.75rem', '3rem'],
      "mb": "1rem",
      "align-items": "center"
    }
  }, [_vm._v(" Grup pendampingan di WhatsApp akan dimulai dalam 1 x 24 jam setelah mengisi Formulir Gizi. Jika pengisian Formulir Gizi dilakukan pada hari Sabtu, Minggu, dan hari libur nasional, maka grup pendampingan di WhatsApp akan dimulai pada hari Senin atau hari kerja pertama. ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": [400, 500],
      "mb": "1rem"
    }
  }, [_vm._v(" Jika ada kendala selama program, kamu bebas untuk menyampaikannya kepada klien manajer ya. ")]), _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": ['row-reverse', 'row'],
      "py": ['0.75rem', '1rem'],
      "px": ['0.75rem', '3rem'],
      "mb": "1rem",
      "align-items": "center",
      "background": "#C7F9E3"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "flex-grow": "1",
      "ml": ['12px', '0px'],
      "mr": ['0px', '12px'],
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Kami disini untuk membantu kamu merubah kebiasaan menjadi sehat dan mencapai goal sehatmu, tapi untuk mencapainya kami meminta kerjasamanya untuk selalu terbuka akan kesulitan kamu, sehingga kami dapat maksimal memberikan rekomendasi, untukmu mencapai hidup lebih sehat :) ")]), _c('c-image', {
    attrs: {
      "flex-shrink": "0",
      "src": "https://ik.imagekit.io/dietela/pwa_webp/email/notif_questionnaire_eating.webp?updatedAt=1682497423559",
      "w": ['60px', '72px']
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['10px', '16px'],
      "font-style": "italic",
      "mb": "1rem",
      "line-height": "150%"
    }
  }, [_vm._v(" *Standar operasional dietela: Personalized meal plan dapat diakses dalam 2 x 24 jam setelah video konseling awal. Bagi klien yang berlangganan lebih dari 30 hari, personalized meal plan dapat diakses 2 x 24 jam setelah konseling evaluasi setiap bulannya. ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "font-family": "Roboto",
      "font-size": ['12px', '16px'],
      "line-height": "150%"
    }
  }, [_c('c-text', [_vm._v(" Salam Sehat, ")]), _c('c-text', [_vm._v(" Nutrition Service Director ")]), _c('c-text', [_vm._v(" Ulfa Safira, S.Gz. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }